<template>
  <div>
    <CRow>
      <CCol sm="12">
        <CCard>
          <CCardHeader> <strong>Edit Product</strong> </CCardHeader>
          <CForm autocomplete="off">
            <CCardBody>
              <CRow>
                <CCol sm="12">
                  <div class="mb-3 text-center">
                    <input
                      class="d-none"
                      type="file"
                      @change="selectFile"
                      accept="image/*"
                      ref="image"
                    />
                    <img
                      @click="$refs.image.click()"
                      height="150px"
                      :src="imageUrl"
                    />
                  </div>
                </CCol>
              </CRow>
              <CRow>
                <div class="col-md-12 mb-3">
                  <v-select
                    @open="onOpen"
                    @close="onClose"
                    @search="triggerSearch"
                    :options="product_models"
                    label="name"
                    v-model="selected_product_model"
                    placeholder="Search and select product model"
                  >
                    <template slot="option" slot-scope="option">
                      {{ option.name }} &#8594;
                      <CIcon name="ProductCategory" />
                      <span class="badge badge-light"
                        >{{ option.product_category.name }}
                      </span>
                      &#8594; <CIcon name="Brand" />
                      <span class="badge badge-light"
                        >{{ option.brand.name }}
                      </span>
                      &#8594; <CIcon name="ProductGroup" />
                      <span class="badge badge-light"
                        >{{ option.product_group.name }}
                      </span>
                    </template>
                    <template slot="selected-option" slot-scope="option">
                      {{ option.name }} &#8594;
                      <CIcon name="ProductCategory" />
                      <span class="badge badge-light"
                        >{{ option.product_category.name }}
                      </span>
                      &#8594; <CIcon name="Brand" />
                      <span class="badge badge-light"
                        >{{ option.brand.name }}
                      </span>
                      &#8594; <CIcon name="ProductGroup" />
                      <span class="badge badge-light"
                        >{{ option.product_group.name }}
                      </span>
                    </template>
                    <template #search="{ attributes, events }">
                      <input
                        class="vs__search"
                        :required="!selected_product_model"
                        v-bind="attributes"
                        v-on="events"
                      />
                    </template>
                    <template #list-footer>
                      <li
                        ref="load"
                        class="loader text-center"
                        v-show="page && isFetching && !selected_product_model"
                      >
                        <CSpinner color="info" size="sm" />
                        <span class="ml-2 text-muted"
                          >fetching product models...</span
                        >
                      </li>
                      <li class="loader text-center" v-show="isSearching">
                        <CSpinner color="info" size="sm" />
                        <span class="ml-2 text-muted">searching...</span>
                      </li>
                    </template>
                    <span slot="no-options">
                      <li
                        class="loader text-center"
                        v-show="
                          !isSearching && !product_models.length && !isFetching && !selected_product_model
                        "
                      >
                        <CIcon name="emojiSad" />
                        <span class="ml-2 text-muted">nothing found!</span>
                      </li>
                    </span>
                  </v-select>
                </div>
              </CRow>
              <CRow>
                <CCol sm="6">
                  <CInput
                    type="text"
                    autocomplete="off"
                    label="Brand Name"
                    v-model="product_to_edit.name"
                    placeholder="Enter brand name..."
                    required
                    @keyup="check_name"
                    :addInputClasses="name_invalid ? 'is-invalid' : null"
                    :isValid="product_to_edit.name ? true : null"
                  />
                </CCol>
                <CCol sm="3">
                  <CInput
                    type="number"
                    autocomplete="off"
                    label="Stock Notify"
                    v-model="product_to_edit.stock_notify"
                    placeholder="Notify when stock quantity is..."
                    required
                    min="0"
                    @change="check_stock_notify"
                    :addInputClasses="
                      stock_notify_invalid ? 'is-invalid' : null
                    "
                    :isValid="product_to_edit.stock_notify ? true : null"
                  />
                </CCol>
                <CCol sm="3">
                  <CInput
                    type="number"
                    autocomplete="off"
                    label="Sale Price"
                    v-model="product_to_edit.price"
                    placeholder="Enter product sale price..."
                    required
                    min="0"
                    @change="check_price"
                    :addInputClasses="price_invalid ? 'is-invalid' : null"
                    :isValid="product_to_edit.price ? true : null"
                  />
                </CCol>
              </CRow>
              <CRow>
                <CCol sm="12">
                  <CTextarea  rows="6"
                    label="Description"
                    v-model="product_to_edit.description"
                    placeholder="Enter Description..."
                  />
                </CCol>
              </CRow>
            </CCardBody>
            <CCardFooter>
              <CButtonToolbar class="justify-content-between">
                <CButton
                  size="sm"
                  color="success"
                  @click="submit"
                  v-if="!isSaving"
                  ><CIcon name="cil-check-circle" /> Update</CButton
                >
                <CButton color="secondary" class="px-4" disabled v-else>
                  <CSpinner grow size="sm" /> Saving..
                </CButton>
              </CButtonToolbar>
            </CCardFooter>
          </CForm>
        </CCard>
      </CCol>
    </CRow>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { api } from "@/api";
export default {
  name: "ProductEdit",
  data() {
    return {
      product_to_edit: [],
      changedImage: null,
      price_invalid: false,
      stock_notify_invalid: false,
      name_invalid: false,
      image: null,
      selected_product_model: {},
    };
  },
  watch: {
    searchKeyword: function (val) {
      this.searchQuery(val);
    },
    selected_product_model: function (val) {
      if (val === null) {
        this.$nextTick();
        this.observer.observe(this.$refs.load);
        this.fetch_search_items(" ");
      }
    },
  },
  computed: {
    ...mapState({
      editProduct: (state) => state.products.editProduct,
      product_models: (state) => state.products.product_models,
      product_model_fetch_pagination: (state) =>
        state.products.product_model_fetch_pagination,
      isSaving: (state) => state.products.isSaving,
      isSearching: (state) => state.products.isSearching,
      isLoading: (state) => state.products.isLoading,
      isFetching: (state) => state.products.isFetching,
    }),
    page() {
      return this.product_model_fetch_pagination.current >=
        this.product_model_fetch_pagination.last
        ? false
        : this.product_model_fetch_pagination.current + 1;
    },
    imageUrl() {
      return this.changedImage
        ? this.changedImage
        : this.product_to_edit.image ||
            `${api.url}core/public/uploads/no-photo.png`;
    },
  },
  created() {
    if (!this.$store.state.access_token) {
      this.$router.push({ name: "Login" });
    }
    if (!this.editProduct) {
      this.$router.push({ name: "Products" });
    }
    this.$store.commit("products/loading", true);
    this.beforeEditingCache = JSON.stringify(this.editProduct);
    this.product_to_edit = this.editProduct
      ? JSON.parse(this.beforeEditingCache)[0]
      : [];
    this.selected_product_model = {
      id: this.product_to_edit.product_model.id,
      name: this.product_to_edit.product_model.name,
      product_group: {
        id: this.product_to_edit.product_group.id,
        name: this.product_to_edit.product_group.name,
      },
      product_category: {
        id: this.product_to_edit.product_category.id,
        name: this.product_to_edit.product_category.name,
      },
      brand: {
        id: this.product_to_edit.brand.id,
        name: this.product_to_edit.brand.name,
      },
    };
  },
  mounted() {
    this.observer = new IntersectionObserver(this.infiniteScroll);
  },
  methods: {
    triggerSearch(keyword) {
      this.searchKeyword = keyword;
    },
    async onOpen() {
      if (!this.selected_product_model) {
        this.$store.commit("products/fetching", true);
        await this.$nextTick();
        this.observer.observe(this.$refs.load);
      } else {
        this.observer.disconnect();
      }
    },
    onClose() {
      this.observer.disconnect();
    },
    infiniteScroll([{ isIntersecting }]) {
      if (isIntersecting) {
        if (this.page && !this.selected_product_model) {
          this.$store.dispatch("products/getProductModels", this.page);
        }
      }
    },
    fetch_search_items(keyword) {
      if (typeof keyword === "string" || !keyword) {
        if (keyword.trim().length == 0) {
          if (this.selected_product_model === null) {
            this.$store.commit("products/fetching", true);
            this.$store.dispatch("products/getProductModels");
          }
        } else {
          this.$store.commit("products/fetching", false);
          this.$store.dispatch("products/fetch_search_items", keyword);
        }
      }
    },
    selectFile(event) {
      this.image = event.target.files[0];
      this.changedImage = URL.createObjectURL(this.image);
    },
    check_name() {
      if (!this.product_to_edit.name) {
        this.name_invalid = true;
      } else {
        this.name_invalid = false;
      }
    },
    check_stock_notify() {
      if (!this.product_to_edit.stock_notify) {
        this.stock_notify_invalid = true;
      } else {
        this.stock_notify_invalid = false;
      }
    },
    check_price() {
      if (!this.product_to_edit.price) {
        this.price_invalid = true;
      } else {
        this.price_invalid = false;
      }
    },
    checkForm() {
      if (!this.product_to_edit.name) {
        this.check_name();
      } else {
        return true;
      }
      if (!this.product_to_edit.stock_notify) {
        this.check_stock_notify();
      } else {
        return true;
      }
      if (!this.product_to_edit.price) {
        this.check_price();
      } else {
        return true;
      }
    },
    submit() {
      if (this.checkForm()) {
        const data = new FormData();
        data.append("id", this.product_to_edit.id);
        data.append("img", this.image);
        data.append("name", this.product_to_edit.name);
        data.append("stock_notify", this.product_to_edit.stock_notify);
        data.append("price", this.product_to_edit.price);
        data.append("description", this.product_to_edit.description);
        data.append("product_model_id", this.selected_product_model.id);
        data.append(
          "product_group_id",
          this.selected_product_model.product_group.id
        );
        data.append("brand_id", this.selected_product_model.brand.id);
        data.append(
          "product_category_id",
          this.selected_product_model.product_category.id
        );

        this.$store.dispatch("products/updateProduct", data);
      } else {
        this.checkForm();
      }
    },
  },
};
</script>
 